<template>
<div style="width:100%">
  <v-sheet v-if="registro.gesdato" :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>CONFIRMACIÓN DE DATOS</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
             <v-col cols="12" class="pr-1"  v-if="registro.subestado.id === 6 || session.area === 16" >
              <texto2 :colores="colores" :titulo="'CORREO ELECTRÓNICO DE NOTIFICACIÓN '" :descripcion="registro.gesdato.correo"></texto2>
            </v-col>
             <v-col cols="12" v-if="registro.subestado.id !== 6 && session.area != 16" >
              <v-text-field v-model="form_confirmar.correo" label="CORREO ELECTRÓNICO DE NOTIFICACIÓN *" :rules="[rules.requerido, rules.confirmacionEmail]" :color="colores.primario" :error-messages="messagesError" ></v-text-field>
            </v-col>
          </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'CÓDIGO SFC'" :descripcion="(registro.gesdato.codigo_sfc)? registro.gesdato.codigo_sfc : null"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'CÓDIGO DCF'" :descripcion="(registro.gesdato.codigo_dcf)? registro.gesdato.codigo_dcf : null"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'CÓDIGO TUTELA'" :descripcion="(registro.gesdato.codigo_tutela)? registro.gesdato.codigo_tutela : null"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'CÓDIGO INTERNO'" :descripcion="(registro.gesdato.codigo_interno)? registro.gesdato.codigo_interno : null"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <texto2 :colores="colores" :titulo="'PROTECCIÓN DATOS PERSONALES'" :descripcion="(registro.gesdato.proteccion_dp ===1 )? 'SI':'NO'"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
           <v-form ref="form_confirmar">
          <br>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <confirmacionDatos v-if="false" :form_confirmar="form_confirmar" :rules="rules" :colores="colores"></confirmacionDatos>
            </v-col>
          </v-row>
          <br>
            <v-row no-gutters v-if="registro.subestado.id !== 6 && session.area !== 16 && session.area !== 17" >
              <v-col cols="12" class="text-center">
                <v-btn @click="actualizar_registro" block large :color="colores.black" dark>ACTUALIZAR INFORMACIÓN</v-btn>
            </v-col>
          </v-row>
      </v-form>
        </v-row>
        <v-row no-gutters>
           <v-form ref="form_confirmar">
          <br>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <confirmacionDatos v-if="false" :form_confirmar="form_confirmar" :rules="rules" :colores="colores"></confirmacionDatos>
            </v-col>
          </v-row>
          <br>
      </v-form>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
<v-layout row justify-center>
  <v-dialog v-model="updated" persistent max-width="450">
  <v-card>
          <v-toolbar class="headline" color="green darken-2" dark>ESTADO</v-toolbar>
          <v-card-text>
            <br>
            <v-layout row>
              <v-flex xs12 class="pt-5">
                <center>
                  <v-icon dark color="green darken-2" size="30">mdi-check-circle</v-icon> DATOS ACTUALIZADOS
                </center>
                <br>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" dark @click="updated=false">ACEPTAR</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</v-layout>
<v-layout row justify-center>
  <v-dialog v-model="esperar" persistent max-width="450">
    <v-card :color="colores.primario">
        <v-card-text class="pt-4">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </v-card-text>
    </v-card>
  </v-dialog>
</v-layout>
</div>
</template>

<script>
// import texto2 from '@/components/widgets/text2Component.vue'
import recGestiones from '@/js/rec_gestiones.js'
export default {
  name: 'lectura_confirmacionComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue'),
    confirmacionDatos: () => import('@/components/crm/form_confirmacion_datosComponent.vue')
  },
  mixins: [recGestiones],
  props: ['colores', 'registro', 'session', 'rules'],
  data: () => ({
    temporaryEmails: [],
    state: true,
    updated: false,
    esperar: false,
    messagesError: null,
    temp: 0,
    idSetIntervalQR: null,
    structure: /^.+@.+\../,
    form_confirmar: { gestion_id: 0, correo: '', codigo_sfc: '', codigo_dcf: '', codigo_tutela: '', codigo_interno: '', proteccion_dp: false }
  }),
  created () {
    this.asignarCorreo()
  },
  methods: {
    actualizar_registro () {
      this.messagesError = null
      var tempoCorreo = this.form_confirmar.correo.replace(/\s+/g, '')
      this.form_confirmar.correo = tempoCorreo
      this.temporaryEmails = this.form_confirmar.correo.split(';')
      for (let i = 0; i < this.temporaryEmails.length; i++) {
        if (!this.structure.test(this.temporaryEmails[i]) && this.temporaryEmails[i].length > 0) {
          this.state = false
          break
        }
      }
      if (!this.state) {
        this.messagesError = 'Verifique los correos'
        setTimeout(() => {
          this.messagesError = null
          this.state = true
        }, 5000)
      } else {
        this.esperar = true
        this.form_confirmar.gestion_id = this.registro.id
        this.form_confirmar.codigo_sfc = this.registro.gesdato.codigo_sfc
        this.form_confirmar.codigo_dcf = this.registro.gesdato.codigo_dcf
        this.form_confirmar.codigo_tutela = this.registro.gesdato.codigo_tutela
        this.form_confirmar.codigo_interno = this.registro.gesdato.codigo_interno
        this.form_confirmar.proteccion_dp = this.registro.gesdato.proteccion_dp
        this.rec_gestion_confirmar_datos('Confirmar')
      }
    },
    asignarCorreo () {
      this.idSetIntervalQR = setInterval(() => {
        if (this.registro.gesdato != null) {
          if (Object.keys(this.registro.gesdato).length > 0 && this.registro.gesdato.correo) {
            this.form_confirmar.correo = this.registro.gesdato.correo
          }
        }
        this.temp += 1
        if (this.temp >= 11) {
          this.endInterval()
        }
      }, 5000)
    },
    endInterval () {
      clearTimeout(this.idSetIntervalQR)
      return null
    }
  }
}
</script>
